import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const reviews = [
  "Fast and reliable! My PS5 is working like new again. – John D.",
  "Exceptional service. Highly recommended! – Sarah T.",
  "Affordable prices and quick turnaround. – Emily R."
];

const Home = () => {
  const [currentReview, setCurrentReview] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReview((prevReview) => (prevReview + 1) % reviews.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  

  return (
    <div className="home-container">
      <p className="home-description">
        We specialize in repairing phones, laptops, gaming consoles, and custom modifications.
      </p>
      

      {/* Sections for About, Story, and Mission */}
      <div className="about-section">
        <h2>About Us</h2>
        <p>High-quality repair services for all your devices, ensuring your gadgets are working like new!</p>
      </div>
      <div className="story-section">
        <h2>Our Story</h2>
        <p>Founded in 2024, we started as a local service and grew into a trusted name across the community.</p>
      </div>
      <div className="mission-section">
        <h2>Our Mission</h2>
        <p>We’re committed to providing fast, reliable, and affordable repair services for customer satisfaction.</p>
      </div>

      {/* Rotating Customer Reviews */}
      <div className="reviews-section">
        <h2>What Our Customers Say</h2>
        <p className="review">{reviews[currentReview]}</p>
      </div>
    </div>
  );
};

export default Home;
