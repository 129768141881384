import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import emailjs from 'emailjs-com';
import './AppointmentForm.css';

const AppointmentForm = () => {
  const location = useLocation();
  const { serviceType } = location.state || {};
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    details: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      service_type: serviceType,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      date: formData.date,
      details: formData.details
    };

    emailjs.send('service_ko70iol', 'template_86ulg7n', templateParams, 'sjP1-l1DdZesj0UtM')
      .then((response) => {
        console.log('Customer email sent successfully!', response.status, response.text);
      })
      .catch((error) => {
        console.log('Failed to send customer email...', error);
      });

    emailjs.send('service_ko70iol', 'template_4zwgmns', templateParams, 'sjP1-l1DdZesj0UtM')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Your appointment request has been submitted.');
      })
      .catch((error) => {
        console.log('FAILED...', error);
        alert('There was an error submitting your request.');
      });

    // Reset form after submission
    setFormData({
      name: '',
      email: '',
      phone: '',
      date: '',
      details: ''
    });
  };

  return (
    <div className="appointment-form-container">
      <h1>Book an Appointment</h1>
      <form className="appointment-form" onSubmit={handleSubmit}>
        <label>Service Type:</label>
        <input type="text" value={serviceType || ''} readOnly />

        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Your Name"
          required
        />

        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Your Email"
          required
        />

        <label>Phone Number:</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Your Phone Number"
          required
        />

        <label>Date:</label>
        <input
          type="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          required
        />

        <label>Additional Details:</label>
        <textarea
          name="details"
          value={formData.details}
          onChange={handleChange}
          placeholder="Any specific details or requests"
        ></textarea>

        <button type="submit">Book Appointment</button>
      </form>
    </div>
  );
};

export default AppointmentForm;
