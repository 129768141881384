import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './logo.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="logo-title">
          <img src={logo} alt="Garcia Gadget Repair Logo" className="logo" />
          <h1 className="site-title">Garcia Gadget Repair</h1>
        </div>
        
        {/* Hamburger menu icon */}
        <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
        
        {/* Dropdown menu for mobile */}
        <ul className={`navbar-list ${isMenuOpen ? 'open' : ''}`}>
          <li><Link to="/" className="navbar-btn" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/services" className="navbar-btn" onClick={toggleMenu}>Services</Link></li>
          <li>
            <a
              href="https://www.ebay.com/usr/your-ebay-store" // Replace with your eBay store URL
              className="navbar-btn"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleMenu}
            >
              eBay Store
            </a>
          </li>
          <li><Link to="/contact" className="navbar-btn" onClick={toggleMenu}>Contact</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
