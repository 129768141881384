import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Services.css';

const Services = () => {
  const navigate = useNavigate();

  const bookAppointment = (serviceType) => {
    navigate('/appointment', { state: { serviceType } });
  };

  return (
    <div className="services-container">
      <h1 className="services-header">Our Services</h1>
      <div className="service-cards">
        <div className="service-card" onClick={() => bookAppointment('Phone Repair')}>
          <h2>Phone Repair</h2>
          <p>Fast and reliable repair services for smartphones.</p>
          <button className="service-btn">Book Appointment</button>
        </div>
        <div className="service-card" onClick={() => bookAppointment('Laptop/Desktop Repair')}>
          <h2>Laptop/Desktop Repair</h2>
          <p>Expert repairs for computers, including diagnostics and upgrades.</p>
          <button className="service-btn">Book Appointment</button>
        </div>
        <div className="service-card" onClick={() => bookAppointment('Console Repair')}>
          <h2>Console Repair</h2>
          <p>Repair services for gaming consoles like PlayStation, Xbox, and Nintendo.</p>
          <button className="service-btn">Book Appointment</button>
        </div>
        <div className="service-card" onClick={() => bookAppointment('Custom Modifications')}>
          <h2>Custom Modifications</h2>
          <p>Personalized mods for devices, including design and performance upgrades.</p>
          <button className="service-btn">Book Appointment</button>
        </div>
      </div>
    </div>
  );
};

export default Services;
