import React from 'react';
import './Contact.css';  // Add a CSS file for Contact styles


const Contact = () => {
  return (
    <div className="contact-container">
      <h1 className="contact-header">Contact Us</h1>
      <p className="contact-description">We’d love to hear from you! Reach out to us for any queries or support.</p>
      <p className="contact-info">Email: garciapcrepairscontact@gmail.com</p>
      <p className="Business-Inquiry">Email: ggr.llc@garciagadgetrepair.com</p>
      <p className="contact-info">Phone: +1 (407) 907-2398</p>
    </div>
  );
};

export default Contact;
